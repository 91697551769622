import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            twitterUsername
            ogImage
            twitterImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`${site.siteMetadata.title}`}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            property: 'og:title',
            content: site.siteMetadata.title,
          },

          {
            property: 'og:site_name',
            content: 'Atomic Farmer',
          },
          {
            property: 'og:locale',
            content: 'en_US',
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:url',
            content: 'https://atomicfarmer.com/',
          },
          {
            property: 'og:image',
            content: `https://atomicfarmer.com/${site.siteMetadata.ogImage}`,
          },
          {
            property: 'og:image:width',
            content: '1200',
          },
          {
            property: 'og:image:height',
            content: '630',
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:title',
            content: site.siteMetadata.title,
          },
          {
            name: 'twitter:site',
            content: site.siteMetadata.twitterUsername,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
          {
            name: 'twitter:image',
            content: `https://atomicfarmer.com/${site.siteMetadata.twitterImage}`,
          },
          {
            name: 'twitter:creator',
            content: site.siteMetadata.twitterUsername,
          },
        ].concat(meta)}
      />
      <Helmet>
        <script>
          {`(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
          (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
          m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
          })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
          ga('create', 'UA-611474-1', 'auto');
          ga('send', 'pageview');`}
        </script>
      </Helmet>
    </Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
